<template>
  <div class="pb" v-if="diaryData?.id && allowEdit">
    <h1>
      {{ $t('create_diary_view_title') }}
    </h1>

    <DiaryEditProgress
      :steps="progressSteps"
      :states="progressStates"
    />



    <h3 ref="elDiarySignup" v-if="!useAuth().isAuth()">
      {{ $t('signup_view_title') }}
    </h3>

    <GeneralSignupShort
      v-if="!useAuth().isAuth()"
      :signup-start-auth="signupStartAuth"
      ref="elSignupForm"
    />

    <template v-if="isLoadedPresaver">
      <br>
      <br>
      <UiMessage
        type="green"
        :button-name="$t('create_week_view_presave_clear')"
        :button-type="'primary'"
        @click-button="presaveClear"
      >
        <b>{{ $t('create_week_view_loaded_from_previous_edit') }}</b>
        <div>
          <span>{{ $t('create_week_view_presave_text1') }}</span>
          {{ $t('create_week_view_presave_text2') }}
        </div>
      </UiMessage>
    </template>

    <h3 ref="elDiaryName">
      {{ $t('create_diary_view_diary_name_title') }} {{ useAuth().getName() }}
    </h3>

    <p>
      {{ $t('create_diary_view_diary_name_subtitle') }}
    </p>

    <div class="head_diary">
      <div class="inf">
        <div>
          <label for="">{{ $t('create_diary_view_diary_name') }}</label>
        </div>

        <input type="text" v-model="diaryData.name">

        <div class="warn">
          {{ $t('create_diary_view_diary_only_single_strain') }}
        </div>
      </div>

      <div v-if="diaryData.id && diaryData.id !== 'new'" class="ava">
        <img class="img" data-not-lazy :src="diaryData.avatar_small" alt="Cover">
        <DiaryEditUploadCover
          :diary_id="diaryData.id"
          @changed="changedCover"
          @uploaded="uploadedCover"
        />
      </div>
    </div>

    <!-- Seeds -->
    <h3 ref="elDiarySeeds">
      {{ $t('create_diary_view_strains_title') }}
    </h3>

    <p>
      {{ $t('create_diary_view_strains_subtitle') }}
    </p>
    <br>

    <div class="items">
      <template v-for="(item, item_key) in getListUniversal(ECategoryType.Seed)" :key="item_key">
        <DiaryEditUniversalViewBox
          :item="item"
          :category="ECategoryType.Seed"
          v-if="!item.is_remove"
          @remove="removeUniversal(item)"
        />
      </template>

      <DiaryEditUniversalSelectBox
        v-if="getListUniversal(ECategoryType.Seed).length"
        :category="ECategoryType.Seed"
        @choose="chooseUniversal($event)"
      />      
      <DiaryEditUniversalEmptyBox
        v-else
        :category="ECategoryType.Seed"
        @choose="chooseUniversal($event)"
      />

    </div>

    <!-- Room Type -->
    <h3 ref="elDiarySetup">
      {{ $t('universal_room_type_title') }}
    </h3>

    <DiaryEditSelectRadioImgBox
      @choose="chooseRoom"
      :list="$constants.typeRoomImg"
      :selected="diaryData.type_room"
    />

    <!-- Grow Tent -->
    <template v-if="diaryData.type_room === 1 || diaryData.type_room === 3">
      <h3>
        {{ $t('universal_tent_title') }}
      </h3>

      <div class="items">
        <template v-for="(item, item_key) in getListUniversal(ECategoryType.Tent)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            v-if="!item.is_remove"
            @remove="removeUniversal(item)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="getListUniversal(ECategoryType.Tent).length"
          :category="ECategoryType.Tent"
          @choose="chooseUniversal($event)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ECategoryType.Tent"
          @choose="chooseUniversal($event)"
        />
      </div>
    </template>

    <!-- Grow Boxes-->
    <template v-if="diaryData.type_room === 1">
      <h3>{{ $t('equip_grow_boxes') }}</h3>

      <div class="items">
        <template v-for="(item, item_key) in getListUniversal(ECategoryType.GrowBox)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            v-if="!item.is_remove"
            @remove="removeUniversal(item)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="getListUniversal(ECategoryType.GrowBox).length"
          :category="ECategoryType.GrowBox"
          @choose="chooseUniversal($event)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ECategoryType.GrowBox"
          @choose="chooseUniversal($event)"
        />
      </div>
    </template>

    <!-- Vegetation Lights -->
    <template v-if="diaryData.type_room === 1 || diaryData.type_room === 3">
      <h3>
        {{ $t('universal_vegetation_lights_title') }}
      </h3>

      <div class="items">
        <template v-for="(item, item_key) in getListUniversal(ECategoryType.Lamp, ELampFaza.veg)" :key="item_key">   
          <DiaryEditUniversalViewBox
            :item="item"
            :category="ECategoryType.Lamp"
            v-if="!item.is_remove"
            @remove="removeUniversal(item)"
            @clone="cloneLampItem"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="getListUniversal(ECategoryType.Lamp, ELampFaza.veg).length"
          :category="ECategoryType.Lamp"
          :faza="ELampFaza.veg"
          @choose="chooseUniversal($event)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ECategoryType.Lamp"
          :faza="ELampFaza.veg"
          @choose="chooseUniversal($event)"
        />
      </div>
    </template>

    <!-- Flowering Lights -->
    <template v-if="diaryData.type_room === 1 || diaryData.type_room === 3">
      <h3>
        {{ $t('universal_flowering_lights_title') }}
      </h3>

      <div class="clone">
        <span class="clone_btn" @click="copyVegetationLamp">
          <i class="icon-clone"></i> {{ $t('create_diary_lamp_clone_vegetation') }}
        </span>
      </div>

      <div class="items">
        <template v-for="(item, item_key) in getListUniversal(ECategoryType.Lamp, ELampFaza.flo)" :key="item_key">          
          <DiaryEditUniversalViewBox
            :item="item"
            :category="ECategoryType.Lamp"
            v-if="!item.is_remove"
            @remove="removeUniversal(item)"
            @clone="cloneLampItem"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="getListUniversal(ECategoryType.Lamp, ELampFaza.flo).length"
          :category="ECategoryType.Lamp"
          :faza="ELampFaza.flo"
          @choose="chooseUniversal($event)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ECategoryType.Lamp"
          :faza="ELampFaza.flo"
          @choose="chooseUniversal($event)"
        />
      </div>
    </template>

    <!-- Ventilation Fans-->
    <template v-if="diaryData.type_room === 1 || diaryData.type_room === 3">
      <h3>{{ $t('equip_ventilation_fans') }}</h3>

      <div class="items">
        <template v-for="(item, item_key) in getListUniversal(ECategoryType.VentilationFan)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            v-if="!item.is_remove"
            @remove="removeUniversal(item)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="getListUniversal(ECategoryType.VentilationFan).length"
          :category="ECategoryType.VentilationFan"
          @choose="chooseUniversal($event)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ECategoryType.VentilationFan"
          @choose="chooseUniversal($event)"
        />
      </div>
    </template>

    <!-- Air Filters-->
    <template v-if="diaryData.type_room === 1 || diaryData.type_room === 3">
      <h3>{{ $t('equip_air_filter') }}</h3>

      <div class="items">
        <template v-for="(item, item_key) in getListUniversal(ECategoryType.AirFilter)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            v-if="!item.is_remove"
            @remove="removeUniversal(item)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="getListUniversal(ECategoryType.AirFilter).length"
          :category="ECategoryType.AirFilter"
          @choose="chooseUniversal($event)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ECategoryType.AirFilter"
          @choose="chooseUniversal($event)"
        />
      </div>
    </template>

    <!-- Air Conditioners-->
    <template v-if="diaryData.type_room === 1 || diaryData.type_room === 3">
      <h3>{{ $t('equip_air_conditioner') }}</h3>

      <div class="items">
        <template v-for="(item, item_key) in getListUniversal(ECategoryType.AirConditioner)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            v-if="!item.is_remove"
            @remove="removeUniversal(item)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="getListUniversal(ECategoryType.AirConditioner).length"
          :category="ECategoryType.AirConditioner"
          @choose="chooseUniversal($event)"
          />
        <DiaryEditUniversalEmptyBox
          v-else
          :category="ECategoryType.AirConditioner"
          @choose="chooseUniversal($event)"
          />
      </div>
    </template>

    <!-- watering -->
    <h3>
      {{ $t('universal_watering_title') }}
    </h3>

    <DiaryEditSelectRadioImgBox
      @choose="chooseWatering"
      :list="$constants.typeWateringImg"
      :selected="diaryData.type_watering"
    />

    <!-- Drip Systems -->
    <template v-if="diaryData.type_watering === 2">
      <h3>{{ $t('equip_drip_systems') }}</h3>

      <div class="items">
        <template v-for="(item, item_key) in getListUniversal(ECategoryType.DripSystem)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            v-if="!item.is_remove"
            @remove="removeUniversal(item)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="getListUniversal(ECategoryType.DripSystem).length"
          :category="ECategoryType.DripSystem"
          @choose="chooseUniversal($event)"
        />
      <DiaryEditUniversalEmptyBox
        v-else
        :category="ECategoryType.DripSystem"
        @choose="chooseUniversal($event)"
      />
      </div>
    </template>

    <!-- Hydroponic Systems -->
    <template v-if="diaryData.type_watering === 3">
      <h3>{{ $t('equip_hydroponic_systems') }}</h3>

      <div class="items">
        <template v-for="(item, item_key) in getListUniversal(ECategoryType.HydroponicSystem)" :key="item_key">
          <DiaryEditUniversalViewBox
            :item="item"
            v-if="!item.is_remove"
            @remove="removeUniversal(item)"
          />
        </template>

        <DiaryEditUniversalSelectBox
          v-if="getListUniversal(ECategoryType.HydroponicSystem).length"
          :category="ECategoryType.HydroponicSystem"
          @choose="chooseUniversal($event)"
        />
      <DiaryEditUniversalEmptyBox
        v-else
        :category="ECategoryType.HydroponicSystem"
        @choose="chooseUniversal($event)"
      />
      </div>
    </template>

    <!-- Controllers-->
    <h3>{{ $t('equip_controllers') }}</h3>

    <div class="items">
      <template v-for="(item, item_key) in getListUniversal(ECategoryType.Controller)" :key="item_key">
        <DiaryEditUniversalViewBox
          :item="item"
          v-if="!item.is_remove"
          @remove="removeUniversal(item)"
        />
      </template>

      <DiaryEditUniversalSelectBox
        v-if="getListUniversal(ECategoryType.Controller).length"
        :category="ECategoryType.Controller"
        @choose="chooseUniversal($event)"
      />
      <DiaryEditUniversalEmptyBox
        v-else
        :category="ECategoryType.Controller"
        @choose="chooseUniversal($event)"
      />
    </div>

    <!-- medium -->
    <h3>
      {{ $t('universal_grow_medium_title') }}

      <span class="medium_used">
        {{ $t('create_diary_grow_medium_used') }}
        {{ total_medium }}%
      </span>
    </h3>

    <div class="items">
      <template v-for="(item, item_key) in getListUniversal(ECategoryType.Medium)" :key="item_key">
        <DiaryEditUniversalViewBox
          v-if="!item.is_remove"
          :item="item"
          :total-medium="total_medium"
          :category="ECategoryType.Medium"
          @remove="removeUniversal(item, ECategoryType.Medium)"
        />
      </template>

      <DiaryEditUniversalSelectBox
        v-if="getListUniversal(ECategoryType.Medium).length"
        :category="ECategoryType.Medium"
        @choose="chooseUniversal($event, ECategoryType.Medium)"
      />
      <DiaryEditUniversalEmptyBox
        v-else
        :category="ECategoryType.Medium"
        @choose="chooseUniversal($event, ECategoryType.Medium)"
      />
    </div>

    <!-- CO2 Generaots -->
    <h3>{{ $t('equip_co2_generators') }}</h3>

    <div class="items">
      <template v-for="(item, item_key) in getListUniversal(ECategoryType.CO2Generator)" :key="item_key">
        <DiaryEditUniversalViewBox
          :item="item"
          v-if="!item.is_remove"
          @remove="removeUniversal(item)"
        />
      </template>

      <DiaryEditUniversalSelectBox
        v-if="getListUniversal(ECategoryType.CO2Generator).length"
        :category="ECategoryType.CO2Generator"
        @choose="chooseUniversal($event)"
      />  
      <DiaryEditUniversalEmptyBox
        v-else
        :category="ECategoryType.CO2Generator"
        @choose="chooseUniversal($event)"
      />
    </div>

    <br>

    <UiButtons>
      <UiButton type="primary" :is-loading="isLoadingSave" :name="$t('universal_button_save')" @click="save"/>
      <UiButton v-if="diaryData.id" :name="$t('universal_button_cancel')" @click="cancel"/>
    </UiButtons>
  </div>
  <div v-else-if="diaryData?.id && !allowEdit">
    <UiNoAccess />
  </div>
</template>

<script setup>

import { ECategoryType, ELampFaza } from "~/types/Enums";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const {$api, $tagsUtil, $popup, $ga, $scrollto, $presaver} = useNuxtApp()
const isLoadingSave = ref(false);
const elDiaryName = ref(null);
const elDiarySeeds = ref(null);
const elDiarySetup = ref(null);
const elDiarySignup = ref(null);
const signupStartAuth = ref(false);
const elSignupForm = ref(null);
const isLoadedPresaver = ref(false);
const id = ref(route.params['id']);
const presaver = $presaver(id.value, 'diary');

const def = ref({
  id: id.value,
  items_equip: [],
  items_lamp_flo: [],
  items_lamp_veg: [],
  items_medium: [],
  name: null,
  type_room: 1,
  type_watering: null
});

const progressSteps = ref([
  {
    id: 'name',
    name: t('create_diary_view_navigation_dairy_name'),
    hintGood: t('create_diary_view_navigation_way_to_go'),
    hintNormal: t('create_diary_view_navigation_give_it'),
    hintBad: t('create_diary_view_navigation_give_it'),
    el: elDiaryName,
  },
  {
    id: 'strains',
    name: t('create_diary_view_navigation_strains'),
    hintGood: t('create_diary_view_navigation_terrific'),
    hintNormal: t('create_diary_view_navigation_what_strains'),
    hintBad: t('create_diary_view_navigation_what_strains'),
    el: elDiarySeeds,
  },
  {
    id: 'setup',
    name: t('create_diary_view_navigation_setup'),
    hintGood: t('create_diary_view_navigation_you_rock'),
    hintNormal: t('create_diary_view_navigation_good_any_more_info'),
    hintBad: t('create_diary_view_navigation_lets_talk_about'),
    el: elDiarySetup,
  },
]);

const progressStates = computed(() => {
  const seedList = diaryData.value.items_equip?.filter(item => item?.category === ECategoryType.Seed && item.is_remove !== 1)
  const lampVeg = diaryData.value.items_equip?.filter(item => item && item.props?.faza === ELampFaza.veg && item?.category === ECategoryType.Lamp && item.is_remove !== 1)
  const lampFlo = diaryData.value.items_equip?.filter(item => item && item.props?.faza === ELampFaza.flo && item?.category === ECategoryType.Lamp && item.is_remove !== 1)
  const extraEqups = diaryData.value.items_equip?.filter(item => item && item?.category !== ECategoryType.Lamp && item?.category !== ECategoryType.Seed && item.is_remove !== 1)

  let ret = {
    name: 'bad',
    strains: 'bad',
    setup: 'bad',
  };

  // name
  if (diaryData.value.name) ret.name = 'good';

  // strains
  if (seedList?.length) ret.strains = 'good';

  // setup
  let setup = 0;

  if (diaryData.value.items_medium?.length) setup++

  if ((diaryData.value.type_room === 1 || diaryData.value.type_room === 3) && (lampVeg?.length || lampFlo?.length)) {
    setup++
  }

  if ((diaryData.value.type_room === 1 || diaryData.value.type_room === 3) && extraEqups?.length) {
    setup++
  }

  if (diaryData.value.type_room) {
    diaryData.value.type_room === 2 ? setup += 4 : setup += 2
  }

  console.log('setup', setup);
  console.log('extraEqups?.length', extraEqups?.length);
  console.log('diaryData.value.type_room', diaryData.value.type_room);

  if (setup < 1) ret.setup = 'bad';
  else if (setup >= 1 && setup <= 4) ret.setup = 'normal';
  else ret.setup = 'good';

  return ret;
})

const chooseUniversal = (item, category) => {
  if (category === ECategoryType.Medium) {
    diaryData.value.items_medium.push(item)
  } else {
    diaryData.value.items_equip.push(item)
  }
}

const removeUniversal = (item, category) => {
  if (category === ECategoryType.Medium) {
    if (id.value === 'new') {
      diaryData.value.items_medium = diaryData.value.items_medium.filter((el) => el !== item)
    } else {
      diaryData.value.items_medium.forEach((el) => {
        if (el === item) el.is_remove = 1
      })
    }
  } else {
    if (id.value === 'new') {
      diaryData.value.items_equip = diaryData.value.items_equip.filter((el) => el !== item)
    } else {
      diaryData.value.items_equip.forEach((el) => {
        if (el === item) el.is_remove = 1
      })
    }
  }
}

const cloneLampItem = (item) => {
  let item_c = JSON.parse(JSON.stringify(item))
  item_c.ref_id = null

  diaryData.value.items_equip.push(item_c)
}

const copyVegetationLamp = () => {
  let items_c = diaryData.value.items_equip.map((item) => {
    if (item && item.props?.faza === ELampFaza.veg && item?.category === ECategoryType.Lamp) {
      let item_c = JSON.parse(JSON.stringify(item));
      item.props.faza = ELampFaza.flo
      item_c.ref_id = null

      return item_c
    }
  })

  diaryData.value.items_equip.push(...items_c)
}

const getListUniversal = (categoryType, faza = undefined) => {
  if (!diaryData.value?.items_equip?.length && !diaryData.value?.items_medium) return []

  if (categoryType === ECategoryType.Lamp && faza === ELampFaza.flo) {
    return diaryData.value.items_equip?.filter((item) => item?.props?.faza === ELampFaza.flo && item?.category === categoryType && !item.is_remove)
  }

  if (categoryType === ECategoryType.Lamp && faza === ELampFaza.veg) {
    return diaryData.value.items_equip?.filter((item) => item?.props?.faza === ELampFaza.veg && item?.category === categoryType && !item.is_remove)
  }

  if (categoryType === ECategoryType.Medium) {
    return diaryData.value?.items_medium
  } else {
    return diaryData.value?.items_equip
      ? diaryData.value.items_equip?.filter((item) => item?.category === categoryType && !item.is_remove)
      : []
  }
}

const total_medium = computed(() => {
  let total = 0;
  const arr = diaryData.value.items_medium

  if (!arr) return total;

  for (var i of arr) {
    total += Number(i.value);
  }

  return total;
});


const chooseWatering = function(id) {
  diaryData.value.type_watering = parseInt(id)
}

const chooseRoom = function(id) {
  diaryData.value.type_room = parseInt(id)
}

const cancel = function() {
  var url = id.value == 'new' ? '/explore' : '/diaries/' + id.value;

  if (id.value == 'new') {
    var url = '/explore';
  } else {
    var url = '/diaries/' + diaryData.value.link;
  }

  router.push({
    path: url
  })
}

const isValid = function() {
  const seedList = diaryData.value.items_equip?.filter(item => item?.category === ECategoryType.Seed && !item.is_remove).length

  if (!diaryData.value.name) {
    $scrollto({
      el: elDiaryName,
      offset: 130,
    })

    $popup.error('Empty diary name');
    return false;
  }

  if (!seedList) {
    $scrollto({ el: elDiarySeeds, offset: 130 })
    $popup.error('Empty strains');
    return false;
  }

  return true;
}

const save = async function() {
  if (!useAuth().isAuth()) {
    $scrollto({
      el: elDiarySignup,
      offset: 130,
    })

    var signupRes = await elSignupForm.value.signUpHandler()

    if (!signupRes) {
      console.log('not ready');
      return false;
    } else {
      console.log('ready');
    }
  }

  if (!isValid()) {
    return false;
  }

  isLoadingSave.value = true;

  // Remove null object from items_equip
  const copyDiaryData = JSON.parse(JSON.stringify(diaryData.value))
  copyDiaryData.items_equip = copyDiaryData.items_equip.filter(item => item)

  // console.log('copyDiaryData', copyDiaryData);return;

  if (!copyDiaryData.id || copyDiaryData.id == 'new') {
    $api.postDiary(copyDiaryData)
      .then((res) => {
        var data = res.data.value.data;
        $popup.success(t('create_diary_view_message_success'));
        $ga.addDiary();
        isLoadingSave.value = false;
        presaver.clearType('diary');
        router.push('/diaries/' + data.link);
      })
      .catch(function (error) {
        isLoadingSave.value = false;
        $popup.error(t('create_diary_view_error_failed'));
      });
  } else {
    $api.putDiary(copyDiaryData.id, copyDiaryData)
      .then((res) => {
        var data = res.data.value.data;
        $popup.success(t('create_diary_view_message_success'));
        isLoadingSave.value = false;
        presaver.clearType('diary');
        router.push('/diaries/' + data.link);
        presaveClear();
      })
      .catch(function (error) {
        console.log(error);
        $popup.error(t('create_diary_view_error_failed'));
        isLoadingSave.value = false;
      });
  }
}

const changedCover = function(uploading_id) {
  console.log('loading', uploading_id)
}

const uploadedCover = function(data) {
  diaryData.value.avatar_small = data;
}

const presaveClear = async function() {
  isLoadedPresaver.value = false;
  presaver.clearType('diary');
  diaryData.value = await loadData(id.value);
}

const allowEdit = computed(() => {
  if (!diaryData.value.id || diaryData.value.id == 'new')
    return true;

  if (diaryData.value.item_user.id == useAuth().getId())
    return true;

  return false;
});

const loadData = async function(id) {
  if (presaver.exists()) {
    isLoadedPresaver.value = true;
    return presaver.get();
  }

  if (id === 'new') {
    return def.value;
  }

  return await $api.getDiary(id)
}

const { pending: is_loading,  data: diaryData } = await useLazyAsyncData('editDiaryData' + id.value, async () => await loadData(id.value))

watch(() => diaryData, (new_diaryData, old_diaryData) => {
  if (new_diaryData.value?.id) {
    console.log('presaver watch');

    // presaver.set(new_diaryData.value);
  }
}, {deep: true});

onMounted(() => {
  if (presaver.exists()) {
    isLoadedPresaver.value = true;
    diaryData.value = presaver.get();
  }
});

</script>

<style scoped>

h1,
h2,
h3{
  margin-top: 1.3rem;
  margin-bottom: 1rem;
}

.medium_used {
  color: #68ae3c;
}

.warn {
  position: relative;
  min-height: 1em;
  margin: 1em 0;
  padding: 1em 1.5em;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
  transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
  border-radius: 0.28571429rem;
  background-color: #FFF8DB;
  color: #B58105;
  box-shadow: 0px 0px 0px 1px #b58105 inset, 0px 0px 0px 0px rgb(0 0 0 / 0%);
}
.items{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* align-items: flex-start; */
}
.head_diary{
  display: flex;
  align-items: center;
  gap:1rem;
}
.head_diary .inf{
  width: 100%;
}
.head_diary .ava{
  width: 180px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.head_diary .ava .img{
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 10px;
}
.clone{
  margin-bottom: 0.5rem;
}
.clone_btn{
  cursor: pointer;
}
</style>